import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="RGPD et ISO 27001 - ExpertSender" description="RGPD et ISO 27001 - développez votre activité à l’aide de l’automatisation du marketing multicanal orienté données pour dépasser la concurrence !" />
        <Hero title="Nous respectons le RGPD à la lettre et sommes certifiés ISO 27001" button="false" anim="false" gdpr="yes" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                  <h3>RGPD et ExpertSender</h3>
                  <p>Le Règlement général sur la protection des données (RGPD) est une loi européenne sur la confidentialité entrée en vigueur le 25 mai 2018. Le RGPD réglemente la façon dont les individus et les organisations peuvent collecter, utiliser et conserver des données personnelles, ce qui a un effet sur la façon dont nos clients peuvent utiliser la plateforme ExpertSender.</p>
                  <h4>À qui s’applique le RGPD ?</h4>
                  <p id="dpo">Le RGPD s’applique à TOUTES les organisations qui collectent et traitent des données personnelles de résidents de l’UE, quel que soit l’emplacement physique de la société. Cela signifie que, si vous avez des clients dans l’UE, mais que votre entreprise n’est pas située elle-même sur ce territoire, vous devez malgré tout vous conformer à la nouvelle réglementation. Vos trouverez une explication plus approfondie sur <a href="https://expertsender.com/blog/does-gdpr-apply-to-you/" target="_blank" rel="noopener noreferrer">les implications du RGPD ici</a>.</p>
                  <h4>Délégué à la protection des données (DPO)</h4>
                  <p class="mar-b-3">Pour savoir si nous traitons vos données personnelles et faire appliquer vos droits, contactez notre délégué à la protection des données (Michał Kidoń) à cette adresse e-mail : dpo@expertsender.com</p>
                  <h3>ExpertSender et ISO 27001</h3>
                  <p>Chez ExpertSender, nous avons choisi d’être certifiés ISO 27001 pour vous garantir que nous sommes aptes à gérer les risques impliqués par la préservation de la sécurité des informations, y compris des données de clients, que nous administrons. Nous voulons que nos clients soient en confiance : en tant qu’organisation, nous appliquons les normes internationales pour gérer activement la sécurité des données et des informations professionnelles.</p>
                  <h4>Qu’est-ce que la norme ISO 27001 ?</h4>
                  <p>ISO 27001/CEI 27001 est un ensemble de normes qui aide les organisations à garantir la sécurité de leurs informations (tant pour leurs systèmes d’informations que pour leurs procédures commerciales internes). Ces normes internationales précisent les exigences d’un système de gestion des informations donnant un véritable contrôle sur la sécurité des informations et présentent une série d’exigences spécifiques permettant aux organisations d’y parvenir.</p>
                  <p>La norme ISO/CEI 27001 prouve qu’une organisation a élaboré et mis en place des procédures de sécurité de l’information selon les meilleures pratiques.</p>
                  <p><strong>Les organisations doivent appliquer les procédures suivantes :</strong></p>
                  <ul>
                    <li>l’examen systématique des risques de sécurité de l’information et la prise en compte les menaces, les vulnérabilités et leur effet potentiel ;</li>
                    <li>la conception et la mise en œuvre d’une suite homogène et complète de contrôles de la sécurité des informations et/ou d’autres formes de traitement du risque (par exemple évitement du risque ou transfert du risque) afin de traiter des risques jugés inacceptables ;</li>
                    <li>l’adoption d’un processus de gestion global qui garantisse que les contrôles de sécurité de l’information soient continus pour répondre aux besoins en sécurité de l’information de l’organisation de manière constante.</li>
                  </ul>
                  <h4>Que signifie la norme ISO 27001 pour nos clients ?</h4>
                  <strong>En utilisant les services d’ExpertSender, vous travaillez avec une organisation qui :</strong>
                  <ul>
                    <li>se protège des cyberattaques et engage les étapes nécessaires pour vous protéger ;</li>
                    <li>a mis en place des contrôles de sécurité adéquats et proportionnés qui contribuent à protéger l’information et à lancer des campagnes marketing conformes aux exigences réglementaires, telles que le RGPD ;</li>
                    <li>engage des revues et des audits internes réguliers de l’ISMS afin d’assurer son amélioration continue.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
          
          <div className="columns is-centered">
            <div className="post__contentWrap column">
              <hr className="mar-b-3" />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-en-US-20210803-20210805081118.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 EN
              </a>
              <br />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-pl-PL-20210803-20210805081130.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 PL
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
